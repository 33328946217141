<template lang="pug">
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  v-window(v-model="tab" continuous)
    v-window-item(value="home"): Home
    v-window-item(value="catalog")
      Catalog(@edit="show.form = true")
    v-window-item(value="catalog2")
      Catalog(img @edit="show.form2 = true")
    v-window-item(value="catalog3")
      Catalog(col3 @edit="show.form2 = true")
    v-window-item(value="catalogList")
      CatalogList(col3 @edit="show.form2 = true")
</template>
<script>
export default {
  components: {
    Home: () => import('T@/components/catalog/Home.vue'),
    Catalog: () => import('T@/components/catalog/Catalog.vue'),
    CatalogList: () => import('T@/components/catalog/CatalogList.vue'),
  },
  data: () => ({
    tab: 'catalog',
    item: 0,
  }),
  methods: {
    dialogRoute(route) {
      console.log('dialogRoute', route)
      this.tab = route
    },
  },
}
</script>
<style lang="stylus">

</style>
